import React from "react"
import styled from "styled-components"
import Logo from "../../assets/images/logo.inline.svg"
import { Link } from "gatsby"
import { MEDIA_QUERIES } from "../../config"
import IconBurger from "../../assets/images/icon-burger.inline.svg"
import IconClose from "../../assets/images/icon-close.inline.svg"
import Container from "./Container"
import { useLocation } from "@reach/router"

const ITEMS = [
  {
    name: "Why PNOĒ",
    to: "/why-pnoe",
  },
  {
    name: "The Science",
    to: "/the-science",
  },
  {
    name: "Virtual Lab",
    to: "/virtual-lab",
  },
  {
    name: "Blog",
    to: "/blog",
  },
  {
    name: "FAQ",
    to: "/faq",
  },
]

const NavItem = styled(Link)`
  opacity: ${props => (props.isActive ? 1 : 0.7)};
  text-decoration: none;
  color: var(--text);
  transition: opacity 200ms linear;
  font-size: 16px;
  font-weight: ${props => (props.isActive ? "bold" : "normal")};
  line-height: 1.14;

  &:hover {
    opacity: 1;
  }
`

const Nav = styled.nav`
  --header-height: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem max(2.4rem, calc(50vw - 1024px / 2));
  min-height: var(--header-height);
`

const DesktopLinks = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 32px;

  ${MEDIA_QUERIES.belowDesktop} {
    display: none;
  }
`

const MobileLinks = styled.div`
  display: none;
  position: fixed;
  right: 0;
  top: var(--header-height);
  height: 100vh;
  width: 100%;
  background: var(--main);
  z-index: 100;
  touch-action: none;
  transform: ${props => (props.isOpen ? "translateX(0)" : "translateX(100%)")};
  transition: transform 300ms ease-in-out;
  flex-direction: column;
  padding: 2rem max(2.4rem, calc(50vw - 1024px / 2));

  ${NavItem} {
    font-size: 2.4rem;
    padding: 1.6rem 0;
    border-bottom: 1px solid grey;
  }

  ${MEDIA_QUERIES.belowDesktop} {
    display: flex;
  }
`

function Header({ isMenuOpen, setIsMenuOpen, theme, ...props }) {
  const location = useLocation()

  function isActivePath(path) {
    if (location.pathname === path) {
      return true
    } else if (location.pathname === `${path}/`) {
      return true
    }

    return false
  }

  return (
    <Container as="header" light={theme === "light"} {...props}>
      <Nav>
        <Link to="/">
          <Logo
            css={`
              height: 30px;
              fill: var(--text);
            `}
          />
        </Link>
        <DesktopLinks>
          {ITEMS.map(item => (
            <NavItem
              to={item.to}
              key={item.to}
              isActive={isActivePath(item.to)}
            >
              {item.name}
            </NavItem>
          ))}
        </DesktopLinks>
        <button
          css={`
            display: none;

            ${MEDIA_QUERIES.belowDesktop} {
              display: block;
            }

            svg {
              fill: var(--text);
            }
          `}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          {isMenuOpen ? <IconClose /> : <IconBurger />}
        </button>
        <MobileLinks isOpen={isMenuOpen}>
          {ITEMS.map(item => (
            <NavItem
              to={item.to}
              key={item.to}
              isActive={isActivePath(item.to)}
            >
              {item.name}
            </NavItem>
          ))}
        </MobileLinks>
      </Nav>
    </Container>
  )
}

export default Header
