import React from "react"
import styled, { css } from "styled-components"
import { MEDIA_QUERIES } from "../../config"
import Button from "../common/Button"
import RawContainer from "../common/Container"
import { H1, P } from "../common/Typography"

const Container = styled(RawContainer).attrs({ light: true })`
  display: flex;
  align-items: center;
  padding-top: ${props => props.py || 0};
  padding-bottom: ${props => props.py || 0};
  position: relative;

  ${MEDIA_QUERIES.belowDesktop} {
    flex-direction: column;
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    text-align: center;

    ${Button} {
      width: 100%;
    }

    .gatsby-image-wrapper {
      margin: 0 -2.4rem;
    }
  }
`

const ImageContainer = styled.div`
  ${props =>
    props.align === "center"
      ? css`
          margin-left: auto;
          margin-right: auto;
        `
      : props.align === "left"
      ? css`
          margin-right: auto;
        `
      : props.align === "right"
      ? css`
          margin-left: auto;
        `
      : ``}
`

const TextContainer = styled.div``

const Title = ({ children, width, ...props }) => (
  <div
    css={`
      max-width: ${width || "45rem"};
    `}
  >
    <H1 mb={16} {...props}>
      {children}
    </H1>
  </div>
)

const Description = ({ children, width, ...props }) => (
  <div
    css={`
      max-width: ${width || "42rem"};
    `}
  >
    <P mb={24} {...props}>
      {children}
    </P>
  </div>
)

const Absolute = styled.div`
  --padding: max(2.4rem, 50vw - 1024px / 2);
  position: absolute;
  left: ${props => (props.left ? "var(--padding)" : "")};
  right: ${props => (props.right ? "var(--padding)" : "")};
  z-index: 11;
`

Container.Image = ImageContainer
Container.Text = TextContainer
Container.Title = Title
Container.Description = Description
Container.Absolute = Absolute

export default Container
